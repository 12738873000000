@import "../../style/theme.style";

.spinnerstyle {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: #0000004f;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1066;
}
