.invoice-modal {
  .bill-table {
    th {
      color: #000 !important;
      border: 2px solid #000 !important;
    }
  }
  .modal-dialog {
    max-width: 1000px !important;
    .modal-content {
      width: 100% !important;
      background-color: #fff !important;
    }
  }

  .setting_field {
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
  }
  .view-invoice {
      position: relative;
      &::after{
        content: "";
        display: block;
        width: 100%;
        height: 30%;
        position: absolute;
        top: 33%;
        left: 30%;
        background-image: url(../../../assets/watermark-logo.png);
        background-size: 300px 255px;
        background-position: 30px 30px;
        background-repeat: no-repeat;
        opacity: 0.7;
      }
    
    .tables {
      border: 2px solid #000 !important;
    }
    .f-700 {
      font-weight: 700;
    }
    section {
      margin: 0 0 100px 0;
    }
    .hr-line {
      border: 2px solid #000;
      margin-top: 0;
    }
    .table td {
      padding: 5px 8px 0px 8px;
      vertical-align: top;
      border-top: 0;
      font-size: 14px;
      font-weight: 700;
      border-right: 2px solid #000;
      margin-left: 30px;
      color: #000;
    }
    .table {
      border-bottom: 2px solid #000;
      border-left: 2px solid #000;
      border-right: 2px solid #000;
      border-top: 2px solid #000;
      // background-color: red !important;
    }

    .bg-title {
      border: 2px solid #000;
      background-color: rgb(179, 175, 175);
      text-align: center;
      color: #000;
    }
    .bg-title p {
      color: #000;
    }
    .table thead th {
      vertical-align: middle;
      border-bottom: 2px solid #000;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      font-weight: 700;
      text-align: center;
      padding: 8px 0;
      background-color: rgb(179, 175, 175);
    }
    .bill-table td {
      padding: 8px 0;
      text-align: center;
      color: #000;
    }
    .account-table td {
      padding: 0;
      border: 2px solid #000;
      color: #000;
    }
    .account-table tr {
      border: 2px solid #000;
    }
    .account-table td hr {
      border: 1px solid #000;
      margin: 0;
      color: #000;
    }
    .account-table td p {
      margin: 0 5px;
      color: #000;
    }
    .f-16 {
      font-size: 16px;
    }
    .u-line {
      text-decoration: underline;
    }
    .account-table {
      table-layout: fixed;
    }
    .bg-sec {
      background-color: rgb(179, 175, 175);
    }
    .date-selector {
      position: relative;
    }

    .date-selector > input[type="date"] {
      text-indent: -500px;
    }
    #datePickerLbl {
      position: absolute;
      /* left: 10px; */
      top: 50%;
      transform: translateY(-50%);
      width: fit-content;
    }
  }
  .f-700 {
    font-weight: 700;
  }
  hr {
    opacity: 0.6 !important;
  }


}
.pointer-events {
  pointer-events: none;
}
.view_table {
  white-space: nowrap;
  margin-bottom: 0 !important;
}
.table-bordered > :not(caption) > * > * {
  border-width: 1px 1px !important;
  border-color: #e8dede !important;
}
.invoice_detail {
  // background-color: #f0f0f0;
  margin: 20px 0;
  padding: 20px 15px;
  @media (max-width: 768px) {
    padding: 20px 1px;
   }
  .details{
    // border: 1px solid #c9c9c9;
    padding: 20px;
    // border-radius: 6px;
    box-shadow: 0 0 50px #ccc;
    background: #fff;
    span{
      display: inline-block;
      width: 200px;
      @media (max-width: 768px) {
       width: 100px;
      }
    }
  }
}


.align-correct {
  display: inline-block;
      width: 150px;
}

.billing-details-table {
  box-shadow: 0 0 50px #ccc;
  border-radius: 6px;
  background: #fff;
}
.payment-table {
  box-shadow: 0 0 50px #ccc;
  background: #fff;
}
.balance-sheet {
  // border: 1px solid #c9c9c9;
  padding: 20px;
  // border-radius: 6px;
  box-shadow: 0 0 50px #ccc;
  background: #fff;
  span{
    display: inline-block;
    width: 225px;
    @media (max-width: 768px) {
      width: 140px;
     }
  }
}

// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// /* Firefox */
// input[type=number] {
//   -moz-appearance: textfield;
// }

// input[type=number]::-webkit-inner-spin-button, 
// input[type=number]::-webkit-outer-spin-button
// { 
//   -webkit-appearance: none; 
//   margin: 0; 
// }

.total-particulars {
  border: 1px solid rgb(177, 177, 177);
  // border-radius: 10px;
  padding: 10px;
}

.border_top td {
  border-top: 2px solid #000 !important;
  border-right: 0 !important;
  // border-color:unset !important;
}
.border_top .border_right {
  border-right: 2px solid #000 !important;
}

// input[type=number]::-webkit-inner-spin-button, 
// input[type=number]::-webkit-outer-spin-button { 
//   -webkit-appearance: none; 
//   margin: 0; 
// }


// style={{ WebkitAppearance: "none", MozAppearance: "textfield" }}
// .no-spinners::-webkit-inner-spin-button,
// .no-spinners::-webkit-outer-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }
// .no-spinners::-webkit-inner-spin-button,
// .no-spinners::-webkit-outer-spin-button {
//   appearance: none;
// }
