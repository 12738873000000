@import "../../style/theme.style";

.companyDetails {
  margin: 50px auto;
  max-width: 850px;
  .companyCard {
    background-color: $card_background;
    border-radius: 10px;
    max-width: 800px;
    margin: 50px auto;
    padding: 20px 30px;
  }
}
