@import "../../style/theme.style";

.fieldsDetails {
  max-width: 800px;
  // padding: 10px 10px;
  margin: 10px auto;
}
.tableName {
  text-transform: capitalize;
}

.modal-content {
  width: 100% !important;
  background-color: $card_background !important;
}

.modal-header {
  background-color: $default_white;
}

.deleteLogo {
  height: 25px;
  width: 25px;
  margin: 3px;
  cursor: pointer;
}

.customInp {
  height: 40px;
  width: 100%;
  outline: none;
  border: 1px solid $default_black;
}

.dropdownTable {
  height: 40px;
  outline: none;
  border: 1px solid $default_black;
}

.customInpLabel {
  font-size: 12px;
}
.testing {
  tr,
  th,
  td {
    color: #000 !important;
  }
}
