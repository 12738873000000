.field_style {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  outline: 0;
  border-radius: 0.375rem;
}
.w-10 {
  width: 45px;
}
.biiling_width {
  max-width: none;
  .searchInput {
    color: #ffffffbd;
    border-bottom: none;
  }
  .searchInput::placeholder {
    color: #fff;
  }
  .tablenav .pageNumber {
    background-color: #fff;
    color: #003bc0;
  }
}
.test_bg {
  .setting_field {
    padding: 7px 10px;
  }
}
.select__control {
  padding: 0 !important;
}
