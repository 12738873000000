@import "../../style/theme.style";

.leves_style {
  margin: 30px auto;
  .leave_div {
    max-width: 700px;
    margin: 0px auto;
    background-color: $card_background;
    padding: 30px;
    margin-top: 30px;
    border-radius: 6px;
    @media (max-width: 767px) {
      padding: 30px 15px;
    }
  }
  .half_day {
    background-image: linear-gradient(
      to right,
      $card_background 45%,
      #d8fbfe 60%
    );
    padding: 4px 10px;
    color: $default_white;
    border-radius: 15px;
    margin: 0;
  }
  .full_day {
    // background-color: #d8fbfe;
    // padding: 4px 10px;
    // color: $default_black;
    // border-radius: 15px;
    // margin: 0;
    background-color: $card_background;
    padding: 4px 10px;
    color: $default_white;
    border-radius: 15px;
    margin: 0;
    border: 0;
    font-size: 14px;
  }
  .select_div {
    background-color: $default_white;
    width: fit-content;
    padding: 4px 4px;
    margin-bottom: 8px;
    border-radius: 25px;
  }
  .slectedoption {
    background: transparent;
    border: 0;
    font-size: 14px;
  }
  .chip_div {
    background: #fff;
    border-radius: 6px;
    @media (min-width: 768px) {
      margin-top: 32px;
    }
  }
  .leave_tab {
    border-bottom: 2px solid #454545;
    .nav-link {
      border: 0;
      font-weight: 700;
      color: #454545;
    }
    .nav-link.active {
      background-color: $card_background;
      color: $default_white;
    }
    @media (max-width: 767px) {
      .nav-link {
        padding: 8px 11px;
      }
    }
  }
}
.react-datepicker__day--selected {
  background-color: transparent !important;
  color: $date_color !important;
}
.react-datepicker__day--selected.react-datepicker__day--highlighted,
.react-datepicker__day--highlighted {
  background-color: $date_bgselect !important;
  color: $default_white !important;
}
.halfday .react-datepicker__day--selected.react-datepicker__day--highlighted,
.halfday .react-datepicker__day--highlighted {
  background-image: linear-gradient(to right, #003bc0 50%, #d8fbfe 55%);
  color: black !important;
}
// .cell_data {
//   border-bottom: 1px solid #fff;
// }
