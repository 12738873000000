.payslipsDetails {
  padding: 10px 10px;
  margin: 10px auto;
  max-width: 650px;
  //   border: 1px solid #000;
  //   border-radius: 10px;
}

.form16card {
  cursor: pointer;
}
