$font_size: 20px;
$error_color: red;
$background_color: linear-gradient(90deg, #000000 70%, #000000bd 100%);
$card_background: #003bc0;
$inp_border: #f3f3f3;
$default_white: #fff;
$button_color: #002a88;
$default_black: #000;
$header_color: #454545;

$date_color: #000;
$date_bgselect: #32be3f;
