@import "../../style/theme.style";

.test_bg {
  background-color: $card_background;
  border-radius: 5px;
  .form-label,
  h5 {
    color: $default_white;
  }
  .select--is-disabled {
    opacity: 1;
  }
  .select__single-value {
    color: #000;
  }
}
