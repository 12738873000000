@import "../../../style/theme.style";

.formsignin_width {
  max-width: 500px;
  margin: 30px auto 30px auto;
  background-color: $card_background;
  padding: 30px;
  border-radius: 6px;

  .form-control {
    border: 0 !important;
    box-shadow: none;
    border-radius: 0;
  }

  .btn_submit {
    background-color: $button_color;
    color: $default_white;
    width: 100%;
    padding: 10px 0;
    margin-top: 30px;
    border: 0;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 600;
  }

  .login_link {
    color: $button_color;
    font-weight: 600;
    text-decoration: none;
  }

  .forgot_link {
    color: $default_white;
    font-weight: 600;
    text-decoration: none;
  }
  @media (max-width: 767px) {
    max-width: inherit;
    margin: 30px 20px;
  }
}
.header_color {
  color: $header_color;
}
.font_color {
  color: $default_white !important;
}
