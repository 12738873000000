@import "../../style/theme.style";

.createuserdiv {
  padding: 10px 0px;
  margin: 10px 0px;
  .form_container {
    background-color: $card_background;
    border-radius: 10px;
    max-width: 700px;
    margin: 0 auto;
  }
}
.goback {
  font-size: 12px;
  font-weight: 600;
  width: fit-content;
  margin: 0 0 16px auto;
  display: block;
}

.uploadBtn {
  text-transform: capitalize !important;
  margin: 0;
}
.file_field {
  padding: 8px 12px;
  border-radius: 6px;
  box-shadow: none;
  outline: none;
  border: 1px solid #000;
}
.mob_style {
  @media (max-width: 420px) {
    overflow: scroll hidden;
  }
}
.select__control {
  padding: 3px;
}
