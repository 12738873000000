.notfoundmaindiv {
  margin: 24px auto;
  .notfoundImagediv {
    width: 100%;
    text-align: center;

    .notfoundimage {
      max-height: 450px;
      max-width: 100%;
      object-fit: cover;
    }
  }
}
