@import "../../style/theme.style";

.setting_style {
  background-color: transparent;
  max-width: 800px;
  margin: 30px auto;
  .acchead {
    .accordion-button {
      box-shadow: none !important;
      color: $default_black;
      font-weight: 600;
      font-size: 20px;
    }
  }
  .information_tab {
    background-color: $card_background;
    border-radius: 6px;
    ul {
      background-color: #97d8e6;
      border-radius: 6px 6px 0 0;
      button {
        border: 1px solid #dee2e6;
      }
    }
    .setting_tab {
      max-width: 500px;
      margin: 0 auto;
      padding: 20px 15px 30px 15px;
    }
  }
}
.setting_field {
  width: 100%;
  padding: 10px 10px;
  border-radius: 6px;
  box-shadow: none;
  outline: none;
  border: 0;
}
.setting_field_cal {
  width: 100%;
  padding: 10px 10px 10px 30px !important;
  border-radius: 6px;
  box-shadow: none;
  outline: none;
  border: 0;
}
.react-datepicker__calendar-icon {
  width: 18px;
  height: 18px;
  top: 50%;
  left: 2%;
  transform: translateY(-50%);
  padding: 0 !important;
}
