.orgchart-container
  > div:first-child
  > ul:first-child
  > li:first-child
  > .oc-node {
  .oc-heading {
    display: none;
  }
  .oc-content {
    border: 0;
    font-size: 20px;
    padding: 0 0 28px 0;
    border-color: transparent !important;
    font-variant: small-caps;
    // width: auto;
  }
  &:hover {
    background-color: transparent !important;
  }
}
// .orgchart-container
//   > div:first-child
//   > ul:first-child
//   > li:first-child
//   > .oc-node:not(:only-child)::after {
//   display: none;
// }
