@import "../../style/theme.style";

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.table_scroll {
  overflow-x: scroll;
}

table,
table th {
  border: 1px solid #f3f3f3 !important;
}

table tr:hover {
  background-color: transparent;
  cursor: pointer;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  color: $default_white;
  background-color: $card_background;
}

table td {
  color: $default_white;
  background-color: $card_background;
}

.cellStyles {
  width: max-content;
  margin: auto;
  .cell_data {
    // padding: 5px !important;
    width: max-content;
    margin: auto;
  }
}
