@import "../../style/theme.style";

.orgchart-container {
  height: 70vh !important;
  width: 100%;
  margin: 0 !important;
  border: 0 !important;
  .orgchart {
    background-image: none !important;
  }
}
.orgchart > ul > li > ul li > .oc-node::before {
  background-color: #000 !important;
}
.orgchart ul li .oc-node:not(:only-child)::after {
  background-color: #000 !important;
}
// .orgchart > ul > li > .oc-node:not(:only-child)::after {
//   display: none !important;
// }
// .orgchart ul li .parent_oc_node.oc-node:not(:only-child)::after {
//   display: none !important;
// }
.orgchart ul li .oc-node .oc-heading {
  background-color: $card_background !important;
}
.orgchart ul li .oc-node .oc-content {
  border-color: $card_background !important;
}
.orgchart > ul > li > ul li::before {
  border-top: 2px solid #000 !important;
}
.orgchart > ul > li > .oc-node > .custom-node > .tooltip {
  display: none;
}
.orgchart > ul > li > .oc-node > .custom-node {
  border: 0;
  // width: auto;
}
.orgchart > ul > li > .oc-node > .custom-node > .node-content {
  border: 0;
  width: auto;
  .emp_name {
    background-color: transparent;
    padding: 0;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: capitalize;
    border: 0;
  }
  .emp_designation {
    display: none;
  }
}

.orgchart ul li .oc-node.selected {
  background-color: transparent !important;
}

// .test_style {
//   padding: 4px 0;
//   .xyz {
//     position: relative;
//     margin: 0 8px;
//   }
//   .xyz::before {
//     content: "";
//     border-top: 2px solid #000;
//     border-left: 2px solid #000;
//     position: absolute;
//     top: -10px;
//     width: 100%;
//     height: 6px;
//   }
//   .xyz:first-child::before {
//     left: auto;
//   }
//   .xyz:last-child::before {
//     left: -50%;
//     border-left: 0;
//     border-right: 2px solid #000;
//   }
//   .xyz::after {
//     content: "";
//     border-bottom: 2px solid #000;
//     border-left: 2px solid #000;
//     position: absolute;
//     bottom: -10px;
//     // left: 0;
//     width: 100%;
//     height: 6px;
//   }
//   .xyz:first-child::after {
//     left: auto;
//   }
//   .xyz:last-child::after {
//     left: -50%;
//     border-left: 0;
//     border-right: 2px solid #000;
//   }
// }
.custom-node {
  border: 1px solid $card_background;
  border-radius: 4px;
  width: 180px;
  .emp_name {
    background-color: $card_background;
    padding: 2px 12px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    min-height: 25px;
  }
  .emp_designation {
    padding: 2px 12px 0px 12px;
    color: #000;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
    text-align: center;
    // height: 20px;
  }
  .matrix_btn {
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid $card_background;
    margin: 8px 0;
    background: $card_background;
    border-radius: 4px;
    padding: 4px 10px;
  }
}

.custom-node.active {
  position: relative;
}

.custom-node.active .tooltip {
  // visibility: hidden;
  width: 200px;
  background-color: #fff;
  // color: #fff;
  // text-align: center;
  border-radius: 6px;
  // padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 50%;
  left: 160px;
  // margin-left: -60px;
  // opacity: 0;
  transform: translateY(50%);
  transition: opacity 0.3s;
  opacity: 1;
  border: 1px solid #555;
}

.custom-node.active .tooltip::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -16px;
  margin-top: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}

.tooltip_design {
  padding: 5px;
  .employee_img {
    width: 40px;
    height: 40px;
    margin: auto;
    object-fit: cover;
    border-radius: 50%;
  }
}
.matrixmodal_btn {
  padding: 0;
  border: 0;
  background: transparent;
  color: red;
  font-size: 12px;
  margin: 0;
}
