@import "../../style/theme.style";

.createSubAdminBtn {
  background-color: $button_color;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0px;
  color: $default_white;
  border: 1px solid $button_color;
  padding: 0px 30px;
}
