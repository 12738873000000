@import "../../../style/theme.style";

.formforgot_width {
  max-width: 500px;
  margin: 90px auto 30px auto;
  background-color: $card_background;
  padding: 30px;
  border-radius: 6px;

  .form-control {
    border: 0 !important;
    box-shadow: none;
    border-radius: 0;
  }

  .login_link {
    color: $button_color;
    font-weight: 600;
    text-decoration: none;
  }

  .forgot_link {
    font-weight: 600;
    text-decoration: none;
    text-transform: capitalize;
    background: $button_color;
    color: $default_white;
    margin-left: 10px;
    padding: 4px 10px;
    border-radius: 6px;
  }
}
