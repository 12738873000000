@import "../../style/theme.style";

.history_data {
  .profile_tab.nav-tabs {
    border-color: #34292980;
    .nav-link {
      color: #fff !important;
      font-weight: 600;
      font-size: 16px;
    }
    .nav-link:hover {
      border-color: $card_background !important;
      border-bottom: 1px solid #34292980 !important;
    }
    .nav-link.active {
      border-color: $card_background !important;
      border-bottom: 1px solid #fff !important;
      background-color: transparent !important;
    }
  }
  @media (max-width: 550px) {
    padding: 20px 15px;
    .profile_tab.nav-tabs .nav-link {
      padding: 10px;
    }
  }
}
.history_btn {
  background-color: transparent;
  border: 0;
  margin: 0 5px;
}
